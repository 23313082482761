.dropdown {

	&.show {

		.dropdown-toggle {

			.sn_sprite {
				transform: rotate3d(1, 0, 0, 180deg);
			}
		}
	}

	.dropdown-toggle {

		&:not(.btn) {
			position: relative;
			padding-right: .8em;
		}

		&::after {
			display: none;
		}

		.sn_sprite {
			position: absolute;
			top: 50%;
			right: 0;
			border: 0;
			margin-top: -.3em;
			@include size(.6em);
			transform: rotate3d(0, 0, 0, 0);
			fill: currentColor;
			transition: transform .35s $transition-cubic-bezier;
		}
	}

	.dropdown-menu {
		box-shadow: 0px 6px 17px 1px rgba(#000, 0.15);
		left: auto;
		right: 0;
		margin-top: 15px;
		padding: 22px 30px;

		&::before {
			content: "";
			position: absolute;
			top: -6px;
			right: 20px;
			@include scut-triangle(up, 10px 6px, #fff);
		}

		.dropdown-item {
			@include font-size(15px);
			font-weight: fw(light);
			border-bottom: 1px solid $gray-400;
			padding: 10px 0;
			text-transform: uppercase;

			&:first-child {
				padding-top: 0;
			}

			&._active {
				color: $primary;
				font-weight: fw(bold);
				pointer-events: none;
			}
		}

		.dropdown-tag {
			@include font-size(12px);
			font-weight: fw(light);
			border: 0;
			padding: 5px 10px;
			text-transform: uppercase;
			color: $gray-700;
			background: $gray-200;
			margin-right: 10px;
			margin-bottom: 10px;

			&:hover {
				background: $gray-400;
			}

			&._active {
				background: $primary;
				color: #fff;
				pointer-events: none;
			}
		}
	}
}

.sn_back_top {
	display: block;
	position: fixed;
	bottom: -50px;
	right: 15px;
	@include size(42px);
	border-radius: 50%;
	background-color: $gray-400;
	fill: $gray-900;
	transition: $transition-base;
	padding: 10px;
	cursor: pointer;
	z-index: $zindex-fixed - 5;
	box-shadow: 0 0 5px 0 rgba(#000, .2);

	@include mq(md) {
		right: 30px;
	}

	&._fix {
		bottom: 15px;

		@include mq(md) {
			bottom: 30px;
		}
	}

	&:hover {
		background-color: $gray-900;
		fill: $gray-400;
	}

	.sn_sprite {
		transform: rotate(180deg);
	}
}

.sn_item {
	$sni: &;
	display: flex;
	flex-direction: column;
	height: 100%;

	&._item_transparent,
	._item_transparent & {
		#{$sni}_ct {
			background: none;
			padding: 0;
			margin-top: 10px;
		}
	}

	._bg_dark._item_transparent & {
		.tags,
		.title,
		.text {
			// color: #fff;
		}

		.tags a {
			color: #fff;

			&:hover,
			&:focus,
			&:active {
				color: $primary;
			}
		}

		#{$sni}_bottom {
			color: #fff;
			fill: #fff;
		}
	}

	&:hover,
	&:focus,
	&:active {
		.title {
			color: $primary !important;
		}

		.btn {
			@extend .btn-primary;
		}
	}

	&_ct {
		background: #fff;
		padding: 25px;
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		@include mq(md){
			padding: 20px;
		}

		@include mq(xl){
			padding: 20px;
		}

		> a {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
		}
	}

	&_ar > .image {
		.title {
			font-family: $greatvibes;
			font-size: 30px;
			color: $white;
			position: absolute;
			bottom: 10px;
			left: 20px;
			z-index: 1;

			@include mq(md){
				font-size: 25px;
			}
		}

		.sn_sprite {
			width: 24px;
			height: 24px;
			content: '';
			position: absolute;
	    bottom: 25px;
			right: 20px;
			margin-left: -12px;
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
			fill: #fff;
			transition: $transition-base;
			z-index: 1;
		}

		&:hover {
			.sn_sprite {
				fill: $primary;
			}
		}

		&::after{
			background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, black 90%);
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 35%;
		}
	}

	&_info {
		color: $body-color;
		fill: $body-color;
		font-family: $font-family-serif;
		text-transform: uppercase;
		font-size: 0;
		line-height: 14px;

		> div {
			font-size: 12px;
			display: inline;

			&::after {
				content: '|';
				font-size: 12px;
				margin: 0 7px;
			}

			&:last-child {
				&::after {
					display: none;
				}
			}

			.sn_sprite {
				@include size(14px);
				margin-right: 3px;
			}
		}
	}

	.image {
		@include scut-ratio-box;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		display: block;
	}

	.tags {
		font-size: 0;
		color: $gray-500;
		list-style: none;
		padding: 0;
		margin: 0 0 10px;
		font-family: $font-family-serif;
		text-transform: uppercase;

		li {
			display: inline;

			&::after {
				content: ' | ';
				font-size: 12px;
			}

			&:last-child {
				&::after {
					display: none;
				}
			}
		}

		a {
			font-size: 12px;
			color: $gray-500;

			&:hover,
			&:focus,
			&:active {
				color: $primary;
			}
		}
	}

	.title {
		@extend .h5;
		font-family: $opensans;
		color: $body-color;
		margin-bottom: 10px;
		transition: $transition-base;
	}

	.text {
		color: $body-color;
		margin-bottom: 15px;
		font-size: 14px;
		@include mq(md) { font-size: 12px; }
		@include mq(xl) { font-size: 16px; }
	}

	.btn {
		@extend .btn-outline-primary;
		margin-top: auto;
		align-self: flex-start;
	}
}

.sn_info_card {
	background: $gray-400;
	padding: 23px 20px;

	.title {
		@extend .h3;
		color: $primary;
		margin-bottom: 15px;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			line-height: 1;
			margin-bottom: 5px;

			> a, > div {
				color: $gray-700;
				fill: $gray-700;
				line-height: 1.2;
				display: inline-flex;
				align-items: flex-start;

				&, p {
					font-size: 16px;
				}

				.sn_sprite {
					@include size(18px);
					flex-shrink: 0;
					margin-right: 8px;
					margin-top: 1px;
				}
			}

			> a {
				&:hover,
				&:focus,
				&:active {
					color: $primary;
					fill: $primary;
				}
			}
		}
	}
}

.sn_room_services {
	&_i {
		text-align: center;
		text-transform: uppercase;
		font-size: 15px;
		font-weight: bold;
		margin-bottom: 36px;

		@include mq(md){
			display: flex;
			align-items: center;
			text-align: left;
			font-size: 16px;
			margin-bottom: 27px;
		}

		@include mq(lg){
			font-size: 18px;
		}

		&:nth-last-child(1),
		&:nth-last-child(2) {
			margin-bottom: 0;
		}

		.sn_sprite {
			@include size(68px);
			display: block;
			margin: 0 auto 18px;
			fill: $gray-400;

			@include mq(md){
				margin: 0 10px 0 0;
			}
		}
	}
}

.sn_site_message {
	text-align: center;
	padding: 15px 0;

	// ._noscroll & {
	// 	height: 0;
	// }

	&:hover {
	}

	&._dark {
		.text {
			color: $dark;
		}
	}

	.text {
		color: #fff;
	}

	.content {
		height: (16px * 1.4);
		font-size: 20px;
		line-height: 1.4;
		width: 100%;
		position: relative;
		transition: $transition-site-message;
		overflow: hidden;

		@include mq(md){
			height: (18px * 1.4);
			font-size: 18px;
		}

		@include mq(lg){
			height: (20px * 1.4);
			font-size: 20px;
		}
	}

	.button {
		margin-top: 20px;
	}
}

// try to customize AddThis
.sn_share {
	margin-top: 30px;

	.addthis_inline_share_toolbox {
		a {
			@include size(48px);
			border: 1px solid $gray-500 !important;
			border-radius: 8px;
			display: inline-flex !important;
			align-items: center;
			justify-content: center;
		}
	}
}

.sn_categories {
	@include font-size(14px);
	display: flex;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
	list-style: none;
	align-items: center;
	color: $gray-500;
	font-family: $font-family-serif;

	li {

		&::before {
			content: "|";
			padding: 0 5px;
		}

		&:first-child {

			&::before {
				display: none;
			}
		}

		a {
			@include font-size(14px);
			color: $gray-500;
			text-transform: uppercase;

			&:hover {
				color: $primary;
			}
		}
	}
}

.sn_tags {
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		display: inline-block;

		a {
			@include font-size(12px);
			font-weight: fw(light);
			border: 0;
			padding: 5px 10px;
			text-transform: uppercase;
			color: $gray-700;
			background: $gray-200;
			margin-right: 10px;
			margin-bottom: 10px;

			&:hover {
				background: $primary;
				color: #fff;
			}
		}
	}
}

.btn {
	font-family: $opensans;
	text-transform: uppercase;
	box-shadow: none !important;
	font-size: 17px;
	padding: 13px 20px;
	line-height: 1.2;

	@include mq(md) {
		padding: 13px 28px;
		font-size: 18px;
	}

	@include mq(xl) {
		font-size: 22px;
	}

	&.btn-sm {
		font-size: 17px;
		padding: 6px 10px;

		@include mq(md) {
			padding: 6px 16px;
			font-size: 18px;
		}

		@include mq(xl) {
			font-size: 20px;
		}
	}

	&.btn-primary {
		color: #fff;
		background-color: $secondary;
		border-color: $secondary;

		&:not(:disabled):not(.disabled) {
			&:hover,
			&:active {
				color: #fff;
				background-color: darken($secondary, 7.5%);
				border-color: darken($secondary, 7.5%);
			}
		}
	}

	&.btn-outline-primary {
		border-color: $secondary;
		color: $secondary;

		&:not(:disabled):not(.disabled) {
			&:hover,
			&:active {
				color: #fff;
				background-color: $secondary;
				border-color: $secondary;
			}
		}
	}
}

.sn_grid_items {
	padding: 75px 0 (100px - 40px);
	background: $quote-bg;

	&._bg_dark {
		background: $offers-bg;
	}

	&_ct {
		transform: translateY(100px);
		opacity: 0;
		transition: $transition-smooth;

		&._in {
			opacity: 1;
			transform: translateY(0px);
		}
	}
}

.element_bg {
	background-color: $gray-200;
}

.block_bg {
	background-color: $white;
}
